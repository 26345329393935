const colors = {
  blue: {
    "50": "#f2f9ff",
    "100": "#e5f4fe",
    "200": "#bfe3fd",
    "300": "#80c7fc",
    "400": "#40acfa",
    "500": "#0090f8",
    "600": "#006cba",
    "700": "#00487c",
    "800": "#00243e",
    "900": "#000e19"
  }
}

export default colors;
