import React, { useState } from 'react';
import {Box, Flex, Button, Link, Heading} from '@chakra-ui/react';

import InputAtom from '../../atoms/Input';
import TextAtom from '../../atoms/Text';
import {HeadBar} from '../../molecules/HeadBar/HeadBar';
import {BriefImageRight} from '../../molecules/BriefImageRight/BriefImageRight';
import {BriefImageLeft} from '../../molecules/BriefImageLeft/BriefImageLeft';
import { AboutUs } from '../../organisms/AboutUs/AboutUs';
import {Footer} from '../../molecules/Footer/Footer';

import getText from '../../helpers/dictionary/getText';

import target_red from '../../../assets/logoAlert128.png';
import target_blue from '../../../assets/logo128.png';

import {ABOUT, HOMEPAGE, ABOUTDSA, DOWNLOAD} from '../../Navigation/paths';

export const HomePage = (props) =>  {

  const callbackAlert = () => {
    alert("Test123")
  }

  const makeHandler = (setState) => {
    return evt => setState(evt.target.value);
  }

  const links = [
    {
      href: ABOUT,
      text: "whatIs"
    },
    {
      href: "#about_us",
      text: "team",
      id: "about-us"
    },
    {
      href: "#contact_us",
      text: "contact"
    }
  ];

  return (
    <Box bg="linear-gradient(#02518A, #001E33);" minH="100vh" >
        <Heading textAlign={'center'} paddingY={15} textColor="white">
          Bakery Studio is coming...
        </Heading>
    </Box>
  )
}
