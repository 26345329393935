import React, { useState } from 'react';

import {
  Box,
  Button,
  Heading,
  VStack,
} from '@chakra-ui/react';

import { LoginForm } from '../../../organisms/LoginForm/LoginForm';
import { SignupForm } from '../../../organisms/SignupForm/SignupForm';

import getText from '../../../helpers/dictionary/getText';

export const Connect = (props) => {
  const [connectType, setConnectType] = useState("login");

  return (
    <Box bg="linear-gradient(#02518A, #001E33);" minH="100vh">
      <VStack >
        <Heading textAlign={'center'} my="8" textColor="white">
          {getText(connectType === 'login' ? "loginPageTitle" : "signupPageTitle")}
        </Heading>
        {
          {
            'login': <LoginForm/>,
            'signup': <SignupForm/>
          }[connectType]
        }
        <Button onClick={() => setConnectType(connectType === 'login' ? 'signup' : 'login')} color="white" fontSize="l" variant="link">
          {getText(connectType === 'login' ? "loginGoToSignup": "signupGoToLogin")}
        </Button>
      </VStack>
    </Box>
  );
}
