const dictionary = {
  "whatIs": {
    "fr": "Qu'est-ce que D'Tekt ?",
    "en": "What is D'Tekt ?"
  },
  "team": {
    "fr": "L'équipe",
    "en": "The team"
  },
  "contact": {
    "fr": "Contactez-nous",
    "en": "Contact us"
  },
  "dtektASite": {
    "fr": "D'Tekter un site",
    "en": "D'Tekt a website",
  },
  "problem": {
    "fr": "Le problème du dropshipping abusif",
    "en": "The problem with abusive dropshipping"
  },
  "discover": {
    "fr": "Découvrez le projet D'Tekt",
    "en": "Discover the project D'Tekt"
  },
  "findoutmore": {
    "fr": "En savoir plus",
    "en": "Find out more"
  },
  "join": {
    "fr": "Rejoignez-nous sur nos réseaux",
    "en": "Join us on our social medias"
  },
  "instagram": {
    "fr": "Instagram",
    "en": "Instagram"
  },
  "twitter": {
    "fr": "Twitter [bientôt]",
    "en": "Twitter [coming soon]"
  },
  "linkedin": {
    "fr": "LinkedIn",
    "en": "LinkedIn"
  },
  "findOnAll": {
    "fr": "Retrouvez D'Tekt sur tous vos appareils",
    "en": "Get D'Tekt on all your devices"
  },
  "extensionFooter": {
    "fr": "Extension navigateur",
    "en": "Browser extension"
  },
  "iOSFooter": {
    "fr": "Application iOS",
    "en": "iOS App"
  },
  "androidFooter": {
    "fr": "Application Android",
    "en": "Android App"
  },
  "readToo": {
    "fr": "À lire aussi",
    "en": "Read also"
  },
  "aboutFooter": {
    "fr": "À propos de D'Tekt",
    "en": "About D'Tekt"
  },
  "DSAFooter": {
    "fr": "Qu'est-ce que le dropshipping ?",
    "en": "What is the dropshipping ?"
  },
  "CGUFooter": {
    "fr": "Conditions générales d'utilisation",
    "en": "Terms of Service"
  },
  "errorRequiredField": {
    "fr": "Ce champ est requis",
    "en": "This field is required"
  },
  "errorInvalidMail": {
    "fr": "Email invalide",
    "en": "Invalid mail"
  },
  "contactUsTitle": {
    "fr": "Contactez nous !",
    "en": "Contact us !"
  },
  "formName": {
    "fr": "Nom",
    "en": "Name"
  },
  "formNamePlaceholder": {
    "fr": "Votre nom",
    "en": "Your name"
  },
  "formEmailTitle": {
    "fr": "Email",
    "en": "Mail address"
  },
  "formEmailPlaceholder": {
    "fr": "Votre email",
    "en": "Your mail address"
  },
  "formMessageTitle": {
    "fr": "Message",
    "en": "Message"
  },
  "formMessagePlaceholder": {
    "fr": "Votre message",
    "en": "Your message"
  },
  "formPasswordTitle": {
    "fr": "Mot de passe",
    "en": "Password"
  },
  "formPasswordPlaceholder": {
    "fr": "******",
    "en": "******"
  },
  "buttonSend": {
    "fr": "Envoyer",
    "en": "Send"
  },
  "aboutObjectiveTitle": {
    "fr": "L'objectif de D'Tekt",
    "en": "D'Tekt's objective"
  },
  "aboutObjectiveText": {
    "fr": "D’Tekt a été créé dans l’objectif de lutter contre les abus du dropshipping et de permettre aux consommateurs d’acheter en ligne en étant sûrs de ne pas se faire arnaquer.",
    "en": "D’Tekt was created with the aim of combating the abuse of dropshipping and allowing consumers to buy online while being sure of not being scammed."
  },
  "aboutHowItWorksTitle": {
    "fr": "Le fonctionnement du projet",
    "en": "How it works"
  },
  "loginTitle": {
    "fr": "Se connecter",
    "en": "login"
  },
  "loginWrongUser": {
    "fr": "Identifiant ou mot de passe incorrect",
    "en": "Incorrect username or password"
  },
  "signupTitle": {
    "fr": "Créer un compte",
    "en": "Sign up",
  },
  "signupAlreadyExist": {
    "fr": "Email déjà utilisé",
    "en": "Email already in use"
  },
  "loginPageTitle": {
    "fr": "Connectez-vous pour accéder à l'API Public de D'Tekt",
    "en": "Login to an account to access the D'Tekt Public API",
  },
  "signupPageTitle": {
    "fr": "Créez-vous un compte pour accéder à l'API Public de D'Tekt",
    "en": "Create an account to access the D'Tekt Public API",
  },
  "loginGoToSignup": {
    "fr": "Vous n'avez pas de comtpe ?",
    "en": "You don't have an account ?",
  },
  "signupGoToLogin": {
    "fr": "Vous avez déjà un compte ?",
    "en": "You already have an account ?",
  },
  "PublicAPIWelcome": {
    "fr": ", Bienvenue dans votre espace personnel",
    "en": ", Welcome to your personal space"
  },
  "PublicAPIProfilButton": {
    "fr": "Mon profil",
    "en": "My profil"
  },
  "serverError": {
    "fr": "Oups, une erreur du côté de notre serveur. Veuillez ressayer plus tard.",
    "en": "Oups, an error by our server. Please try again later.",
  },
  "aboutHowItWorksText": {
    "fr": "La solution D’Tekt analyse les pages marchandes que vous visitez et, si elle détecte que vous vous trouvez sur un site potentiellement abusif, vous avertit.",
    "en": "D'Tekt solution analyse the shopping pages that you visit and, if it detects that you are on a propable drappshipping pag alert you."
  },
  "aboutSecurityTitle": {
    "fr": "Pour un internet plus sain et un commerce plus responsable.",
    "en": "For a safer internet and a more responsible commerce"
  },
  "aboutSecurityText": {
    "fr": "Tout le processus est effectué dans le respect de vos données personnelles. D’Tekt analyse seulement les sites marchands, tous les autres sites que vous consultez ne passent pas la première barrière de notre algorithme et sont ignorés.",
    "en": "The entire process is carried out in compliance with your personal data. D'Tekt only analyzes merchant sites, all other sites you visit do not pass the first barrier of our algorithm and are ignored."
  },
  "aboutUsCardTitle": {
    "fr": "Qui sommes-nous ?",
    "en": "Who are we ?"
  },
  "presentationAbdou": {
    "fr": "Je m'occupe essentiellement du back de l'API Publique. Nous mettons à disposition de nos clients cette API afin qu'ils puissent récupérer certaines données que nous avons récoltées lors de nos différentes analyses. J'intervient aussi sur la partie front web.",
    "en": "I mainly take care of the back of the Public API. We make this API available to our customers so that they can retrieve certain data that we have collected during our various analyses. I also intervene on the front web part."
  },
  "presentationCorentin": {
    "fr": "L'intelligence Artificielle c'est ce qui vous permet de faire du shopping de manière sure et sécurisée ! Je m'assure que vous puissiez être notifiés quand un site est dangereux ou suspect, ou qu'un produit est disponible sur une autre plateforme.",
    "en": "Artificial intelligence is what allows you to shop safely and securely! I make sure that you can be notified when a site is dangerous or suspicious, or that a product is available on another platform."
  },
  "presentationMailys": {
    "fr": "Je m'occupe de l'application mobile côté iOS. Je développe toutes les fonctionnalités côté front vous permettant de détecter la fiabilité d'un site ou d'être notifié en temps réel si vous naviguez sur un site de drop shipping abusif. Aussi je suis responsable de la communication en interne et en externe sur nos différents réseaux sociaux.",
    "en": "I’m in charge of the mobile app on the iOS side. I develop all the front-side features allowing you to detect the reliability of a site or to be notified in real time if you browse a site of abusive drop shipping. Also I am responsible for internal and external communication on our various social networks."
  },
  "presentationMathias": {
    "fr": "Je m'occupe de la partie Android du projet. Je me charge de faire en sorte que le visuelle de l'application soit plaisant et que vous ayez une bonne expérience utilisateur qui permet de la rendre plus fluide et ludique à utiliser.",
    "en": "I’ll take care of the Android part of the project. I make sure that the visual of the application is pleasant and that you have a good user experience that makes it more fluid and playful to use."
  },
  "presentationJohan": {
    "fr": "Personnellement, je m’occupe de la partie web du projet. C’est moi qui m’occupe de ce que vous voyez sur votre écran quand vous consultez notre site. Je travaille également sur l’extension de navigateur pour qu’elle soit agréable à utiliser.",
    "en": "Personally, I deal with the web part of the project. I deal with what you see on your screen when you visit our site. I’m also working on the browser extension to make it enjoyable to use."
  },
  "presentationCleophee": {
    "fr": "Je suis sur la partie Android principalement. Je m'occupe du rendu visuel et de faire fonctionner l'application Android.",
    "en": "I’m on the Android part mainly. I’m dealing with visual rendering and running the Android app."
  },
  "presentationArthur": {
    "fr": "Je suis un développeur Back-End et DevOps du projet. Je m'occupe de la partie back-end du projet, c'est-à-dire le développement et la mise en place des différents services API et serveurs permettant le fonctionnement de nos différentes applications web et mobiles.",
    "en": "I am a Back-End and DevOps developer of the project. I take care of the back-end part of the project, that is to say the development and implementation of the different API services and servers allowing the operation of our different web and mobile applications."
  },
  "aboutDSAOnlineShopTitle": {
    "fr": "L'origine du dropshipping",
    "en": "The origin of dropshipping"
  },
  "aboutDSAOnlineShop": {
    "fr": "De nos jours, le commerce en ligne fait partie intégrante de nos quotidiens. Ce nouveau mode de consommation a entraîné la naissance du dropshipping.",
    "en": "Nowadays, online shopping is fully part of our lives. This new consumation mode led to the creation of dropshipping"
  },
  "aboutDSAWhatIsItTitle": {
    "fr": "Qu'est ce que le dropshipping ?",
    "en": "What is dropshipping ?"
  },
  "aboutDSAWhatIsIt": {
    "fr": "Le dropshipping, c’est le fait pour un marchand de revendre des produits qu’il ne possède pas en stock en les récupérant d’un autre site.",
    "en": "The dropshipping is the fact, for a merchant, to resell products he does not own by reselling from other websites"
  },
  "aboutDSAProblemTitle": {
    "fr": "Le problème avec le dropshipping",
    "en": "The problem with dropshipping"
  },
  "aboutDSAProblem": {
    "fr": "Le problème qui existe pour certains sites, c’est qu’ils en profitent pour abuser de ce système et proposer des prix abusifs et des promotions mensongères, tout en n’ayant aucun service client.",
    "en": "The problem that exists for some websites is that they abuse of this system by proposing abusive prices and misleading promoitions, while having no customer service."
  },
  "extensionIncoming": {
    "fr": "L'extension D'Tekt arrive bientôt...",
    "en": "The D'Text extension is coming soon..."
  },
}

export default dictionary;
