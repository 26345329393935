import React from 'react';
import {Text} from '@chakra-ui/react';
import getText from '../helpers/dictionary/getText';

export const Paragraph = (props) => {
  return (
    <Text ml={props.marginLeft} width={props.width} color={props.color} fontFamily="Poppins, sans-serif" fontSize={props.fontSize} mt="2%">
      {getText(props.text)}
    </Text>
  )
}
