import React from 'react';
import {Flex, Text, Button, Image, Link} from '@chakra-ui/react';
import { useHistory } from "react-router-dom";

import logo from "../../../assets/logo.png";
import { HOMEPAGE } from '../../Navigation/paths';

import getText from '../../helpers/dictionary/getText';
import ReactGA from 'react-ga';

export const HeadBar = (props) => {
  let history = useHistory();
  
  const handleClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Click on Headbar Button'
    })
    console.log("coucoutest")
    history.push(props.buttonHref);
  }

  console.log(props.links);

  const handleRedirect = () => {
    history.push(HOMEPAGE+"#home_page");
  }

  return (
      <Flex width="100%" minH="100px" bg={props.backgroundColor || "blue.800"} justifyContent="space-between" alignItems="center" paddingRight="25px" paddingLeft="25px" position="fixed" zIndex="2">
          <Image src={logo} h="50px" cursor="pointer" onClick={handleRedirect}/>
          <Flex justifyContent="space-between" w="70%" alignItems="center">
            {props.links.map(link => (
              <Link
                id={link.id || ''}
                color="white" href={link.href}
                fontFamily="Poppins, sans-serif"
                fontWeight="bold"
              >
                {getText(link.text)}
              </Link>
            ))}
            <Button colorScheme="blue" onClick={handleClick} fontFamily="Poppins, sans-serif" fontWeight="bold">{getText(props.buttonText)}</Button>
          </Flex>
      </Flex>
  )
}
