import React, { useState } from 'react';
import {Box, Flex, Text, Link} from '@chakra-ui/react';

import {HeadBar} from '../../molecules/HeadBar/HeadBar';
import {Footer} from '../../molecules/Footer/Footer';
import {ABOUT, HOMEPAGE, ABOUTDSA, DOWNLOAD} from '../../Navigation/paths';
import getText from '../../helpers/dictionary/getText';

export const DownloadExt = (props) => {

  const links = [
    {
      href: ABOUT,
      text: "whatIs"
    },
    {
      href: HOMEPAGE + "#about_us",
      text: "team"
    },
    {
      href: HOMEPAGE + "#contact_us",
      text: "contact"
    }
  ];

  return(
    <Box>
      <HeadBar
        links={links}
        buttonText="dtektASite"
        buttonHref="/download"
        backgroundColor="blue.800"
      />
      <Box bg="linear-gradient(#001E33, #02518A);" minH="100vh" pt="150px">
        <Flex h="50vh" justifyContent="center" alignItems="center">
          <Text fontSize="6xl" color="white">{getText("extensionIncoming")}</Text>
        </Flex>
        <Box mt="10%">
          <Footer/>
        </Box>
      </Box>
    </Box>
  )
}
