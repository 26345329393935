import { ChakraProvider } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

import theme from './theme/theme';
import RouterNavigation from './components/Navigation/RouterNavigation';
import ReactGA from 'react-ga';

function App() {

    const initReactGA = () => {
        ReactGA.initialize('G-6XJ7KNDF1D');
        ReactGA.pageview('test-init-pageview');
    }

    useEffect(() => {
        if (!localStorage.getItem("lang")) {
            localStorage.setItem("lang", "fr")
        }
        initReactGA();
    })

    return (
        <div>
            <ChakraProvider theme={theme}>
                <RouterNavigation/>
            </ChakraProvider>
        </div>
    )
}

export default App;