import dictionary from "./dictionary";

export default function getText(key) {
  let paragraph = dictionary[key];

  if (!paragraph) {
    return "ERROR: key {" + key + "} not found";
  }

  let lang = localStorage.getItem("lang") || 'fr';

  if (!paragraph[lang]) {
    return "ERROR: key {" + key + "} for lang {" + lang + "} not found";
  }

  return(paragraph[lang]);
}
