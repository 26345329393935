import React, { useState } from "react";

import {
  VStack,
  Flex,
  Text,
  Input,
  Button,
  Box,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertDescription,
  IconButton,
  CloseButton
} from '@chakra-ui/react';

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { axiosPublic } from "../../helpers/Axios";

import getText from '../../helpers/dictionary/getText';
import { Formik ,Form, Field } from "formik";

import IsEmail from 'isemail';

export const SignupForm = (props) => {

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleShowClick = () => setShow(!show);

  const [errorForm, setErrorForm] = React.useState('');

  const validateName = (value) => {
    let error = '';
    if (!value) {
      error = getText("errorRequiredField")
    }
    return error;
  }

  const validateEmail = (value) => {
    if (!value) {
      return getText("errorRequiredField");
    }
    if (!IsEmail.validate(value)) {
      return getText("errorInvalidMail");
    }
    return '';
  }

  const validatePassword = (value) => {
    if (!value) {
      return getText("errorRequiredField");
    }
    return '';
  }

  const sendSignup = async (value) => {
    setErrorForm('');
    setLoading(true);

    axiosPublic.post('/users/signup', {
      email: value.email,
      password: value.password,
      username: value.name
    })
    .then(result => {
      console.log('result === ', result);
      location.replace('/public');
    })
    .catch(error => {
      if (!error.response) {
        setErrorForm(getText("serverError"));
        return;
      }
      setErrorForm(getText(error.response.data.traduction));
    })
    .finally(() => {
      setLoading(false);
    })
  }

  return (
    <Box width="45%" mx="15%" mt="15px">

      <Formik
        initialValues={{
          email: '',
          password: '',
          name: ''
        }}
        onSubmit={(values) => {
          sendSignup(values);
        }}
      >
        {(props) => (
          <Form>
            <Stack
              bgColor="blue.600"
              borderRadius="50px"
              padding="10"
              spacing="4"
            >
              <VStack style={{ alignItems: "start"}} >
                <Text as="b" color="white" fontSize="3xl">
                  {getText("signupTitle")}
                </Text>
                <Field name="name" validate={validateName}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.name && form.touched.name} isRequired>
                      <FormLabel color="white" htmlFor="name">{getText("formName")}</FormLabel>
                      <Input
                        {...field}
                        isDisabled={loading ? true : false}
                        bgColor="white"
                        textColor="bleu.800"
                        colorScheme="white"
                        placeholder={getText("formNamePlaceholder")}
                        size="lg"
                        textStyle="bold"
                        id="name"
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email" validate={validateEmail}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.email && form.touched.email} isRequired>
                      <FormLabel color="white" htmlFor="email">{getText("formEmailTitle")}</FormLabel>
                      <Input
                        {...field}
                        isDisabled={loading ? true : false}
                        bgColor="white"
                        textColor="bleu.800"
                        colorScheme="white"
                        placeholder={getText("formEmailPlaceholder")}
                        size="lg"
                        textStyle="bold"
                        id="email"
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password" validate={validatePassword}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.password && form.touched.password} isRequired>
                      <FormLabel color="white" htmlFor="password">{getText("formPasswordTitle")}</FormLabel>
                      <Flex>
                        <Input
                          {...field}
                          isDisabled={loading ? true : false}
                          bgColor="white"
                          textColor="bleu.800"
                          colorScheme="white"
                          placeholder={getText("formPasswordPlaceholder")}
                          size="lg"
                          textStyle="bold"
                          id="password"
                          type={show ? 'text' : 'password'}
                        />
                        <IconButton ml={2} size='lg' onClick={handleShowClick}
                          icon={show ? <ViewOffIcon/> : <ViewIcon/> }
                        />
                      </Flex>
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </VStack>
              <VStack width="100%" style={{ alignItems: "end"}}>
                <Button isLoading={loading ? true : false} size="lg" type="submit" textColor="blue.800">
                  {getText("buttonSend")}
                </Button>
              </VStack>
              {errorForm &&
              <Alert status='error' borderRadius="md">
                <AlertIcon />
                <AlertDescription>{errorForm}</AlertDescription>
                <CloseButton position='absolute' right='8px' top='8px'/>
              </Alert>}
            </Stack>
          </Form>
        )}
    </Formik>
    </Box>
  );
}
