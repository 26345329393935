import React from 'react';
import {Text} from '@chakra-ui/react';

function TextAtom(props) {
    return (
      <Text
        fontStyle={props.fontStyle || "normal"}
        fontWeight={props.fontWeight || "normal"}
        fontSize={props.fontSize || "size"}
        align={props.align || "left"}
        color={props.color || "white"}
      >
        {props.text}
      </Text>
    )
}

export default TextAtom;