import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HomePage } from '../pages/HomePage/HomePage';
import { LearnAboutDS } from '../pages/LearnAboutDS/LearnAboutDS';
import { DownloadExt } from '../pages/DownloadExt/DownloadExt';
import {FindOutMore} from '../pages/FindOutMore/FindOutMore';
import { Connect } from '../pages/Public/Connect/Connect';
import { Home } from '../pages/Public/Home/Home';

import {ABOUT, HOMEPAGE, ABOUTDSA, DOWNLOAD, PUBLIC_CONNECT, PUBLIC_HOME} from './paths';

import { axiosPublic } from '../helpers/Axios';
import { isPublicPath } from '../helpers/helpers';
import ProtectedRoute from './ProtectedRoute';

function RouterNavigation() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (!isPublicPath(location.pathname)) {
      return;
    }

    axiosPublic.get('/users/me')
    .then(response => {
      setLoggedIn(response.data._id ? true : false)
    })
    .catch(() => setLoggedIn(false));
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <Route exact path={HOMEPAGE} component={HomePage}/>
          <Route exact path={ABOUT} component={FindOutMore}/>
          <Route exact path={ABOUTDSA} component={LearnAboutDS}/>
          <Route exact path={DOWNLOAD} component={DownloadExt}/>
          <Route exact path={PUBLIC_CONNECT} component={Connect}/>
          <Route exact path={PUBLIC_HOME}
            component={loggedIn ? Home : Connect}
          />
          <Route path={"*"} component = {HomePage}/>
        </Switch>
      </Router>
    </>
  )
}

export default RouterNavigation;
