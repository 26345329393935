import React, { useState } from 'react';
import {Box, Flex, Text, Link} from '@chakra-ui/react';

import {HeadBar} from '../../molecules/HeadBar/HeadBar';
import {Footer} from '../../molecules/Footer/Footer';
import {Paragraph} from '../../helpers/Paragraph';

import getText from '../../helpers/dictionary/getText';
import {ABOUT, HOMEPAGE, ABOUTDSA, DOWNLOAD} from '../../Navigation/paths';

export const LearnAboutDS = (props) => {

  const links = [
    {
      href: ABOUT,
      text: "whatIs"
    },
    {
      href: HOMEPAGE + "#about_us",
      text: "team"
    },
    {
      href: HOMEPAGE + "#contact_us",
      text: "contact"
    }
  ];

  return(
    <Box>
        <HeadBar
          links={links}
          buttonText="dtektASite"
          buttonHref="/download"
          backgroundColor="blue.800"
          />
      <Box bg="linear-gradient(#001E33, #02518A);" minH="100vh" pt="150px">
        <Box>
          <Text ml="5%" width="70%" color="white" fontFamily="Poppins, sans-serif" fontWeight="bold" fontSize="4xl">{getText("aboutDSAOnlineShopTitle")}</Text>
          <Paragraph marginLeft="5%" fontSize="2xl" color="white" width="50%" text="aboutDSAOnlineShop"/>
        </Box>
        <Box mt="5%" justifyContent="right">
          <Text ml="5%" width="70%" color="white" fontFamily="Poppins, sans-serif" fontWeight="bold" fontSize="4xl" text>{getText("aboutDSAWhatIsItTitle")}</Text>
          <Paragraph marginLeft="5%" fontSize="2xl" color="white" width="50%" text="aboutDSAWhatIsIt"/>
        </Box>
        <Box mt="5%" alignSelf="left">
          <Text ml="5%" width="70%" color="white" fontFamily="Poppins, sans-serif" fontWeight="bold" fontSize="4xl">{getText("aboutDSAProblemTitle")}</Text>
          <Paragraph marginLeft="5%" fontSize="2xl" color="white" width="50%" text="aboutDSAProblem"/>
        </Box>
        <Box mt="10%">
          <Footer/>
        </Box>
      </Box>
    </Box>
  )
}
