import React, { useEffect, useState } from 'react';

import {
  Box,
  Heading,
  Stack,
  VStack,
} from '@chakra-ui/react';

import getText from '../../../helpers/dictionary/getText';
import { axiosPublic } from '../../../helpers/Axios';
import { HeadBar } from '../../../molecules/HeadBar/HeadBar';


export const Home = (props) => {

  const [username, setUsername] = useState('')

  useEffect(() => {
    axiosPublic.get('users/me')
    .then(result => {
      setUsername(result.data.username);
    })
    .catch(error => {
      setUsername("Hey");
      console.error(error);
    })
  }, []);

  const links = [

  ];

  return (
    <Box>
      <HeadBar
        links={links}
        buttonText="PublicAPIProfilButton"
        buttonHreef="#"
        backgroundColor="blue.400"
      />
      <Box bg="linear-gradient(#02518A, #001E33);" minH="100vh" >
        <VStack >
          <Heading textAlign={'center'} my="8" textColor="white">
            {username}{getText("PublicAPIWelcome")}
          </Heading>
        </VStack>
      </Box>
    </Box>
  );
}
