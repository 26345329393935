import React from 'react';
import {Center, Flex, Text, Button, Image, Link, VStack} from '@chakra-ui/react';
import { useHistory } from "react-router-dom";

import getText from '../../helpers/dictionary/getText';
import useWindowDimensions from "../../helpers/useWindowDimensions";

export const BriefImageRight = (props) => {
  let history = useHistory();

  const getTextFontSize = () => {
    const {width, height} = useWindowDimensions();
    if (width < 1200)
      return "3xl";
    if (width < 1580)
      return "4xl";
    return "5xl";
  }
  
  const getLinkFontSize = () => {
    const {width, height} = useWindowDimensions();
    if (width < 1200)
      return "xl";
    if (width < 1400)
      return "2xl";
    return "3xl";
  }

  return (
      <Flex width="70%" h="440px" marginLeft="15%" marginRight="15%" justifyContent="space-between" bg={props.backgroundColor} borderRadius="50px" padding="5%">
        <VStack alignItems="right" width="50%">
          <Text fontSize={getTextFontSize} fontFamily="Poppins, sans-serif" fontWeight="bold"  color={props.textColor}>{getText(props.textTitle)}</Text>
          <Link fontSize={getLinkFontSize} href={props.link} fontFamily="Poppins, sans-serif" fontWeight="bold" color={props.textColor}>{getText(props.textSubtitle)}</Link>
        </VStack>
        <Image src={props.image}></Image>
      </Flex>
  )
}
