import React from 'react';
import {Flex, Text, Image, VStack, Link, HStack, Icon} from '@chakra-ui/react';
import { useHistory } from "react-router-dom";

import logo from "../../../assets/logo.png";
import french from "../../../assets/flags/french.png";
import english from "../../../assets/flags/english.jpg";
import {ABOUT, HOMEPAGE, ABOUTDSA, DOWNLOAD} from '../../Navigation/paths';

import getText from '../../helpers/dictionary/getText';
import useWindowDimensions from '../../helpers/useWindowDimensions';

export const Footer = (props) => {
  let history = useHistory();
  let {width, height} = useWindowDimensions();

  const handleClick = () => {
    history.push(props.buttonHref);
  }

  const handleChangeLanguage = (lang) => {
    console.log("coucou456")
    localStorage.setItem("lang", lang);
    window.location.reload(false);
  }

  return (
      <Flex width="100%" minH="200px" bg="blue.800" justifyContent="space-evenly" pt="15px" position="relative" bottom="0">
        <VStack>
          <Text color="white" fontSize="xl">{getText("join")}</Text>
          <Link color="white" href="https://www.instagram.com/d.tekt/">{getText("instagram")}</Link>
          {/*<Link color="white">{getText("twitter")}</Link>*/}
          <Link color="white" href="https://www.linkedin.com/company/d-tekt/about/">{getText("linkedin")}</Link>
        </VStack>
        <VStack>
          <Text color="white" fontSize="xl">{getText("findOnAll")}</Text>
          <Link color="white" href="/download">{getText("extensionFooter")}</Link>
          <Link color="white">{getText("iOSFooter")}</Link>
          <Link color="white">{getText("androidFooter")}</Link>
        </VStack>
        <VStack>
          <Text color="white" fontSize="xl">{getText("readToo")}</Text>
          <Link color="white" href={ABOUT}>{getText("aboutFooter")}</Link>
          <Link color="white" href={ABOUTDSA}>{getText("DSAFooter")}</Link>
          {/* <Link color="white">{getText("CGUFooter")}</Link> */}
          <HStack>
            <Image name="fr" src={french} h="4" cursor="pointer" onClick={() => handleChangeLanguage("fr")}/>
            <Image name="eng" src={english} h="4" cursor="pointer" onClick={() => handleChangeLanguage("en")}/>
          </HStack>
        </VStack>
      </Flex>
  )
}
