import axios from 'axios';

const dtektPublicUrl = process.env.REACT_APP_PUBLIC_API_URL || '';

export const axiosPublic = axios.create({
    withCredentials: true,
    crossorigin: true,
    crossdomain: true,
    baseURL: dtektPublicUrl
});
