import { extendTheme } from "@chakra-ui/react";

import colors from "./Colors/colors";
import buttons from "./Button/button";
import inputs from "./Input/input";
import image from "./Image/image";
import text from "./Text/text";
import link from "./Link/link";

const theme = extendTheme({
  colors,
  components: {
    buttons,
    inputs,
    image,
    text,
    link,
  },
})

export default theme;
